<template>
    <div>
        <v-alert type="success" prominent>
            Potilaan tunniste:
            <strong>{{ patient.id }}</strong>
        </v-alert>

        <v-row>
            <v-col cols="12" sm="6">
                <v-simple-table>
                    <tbody>
                        <tr>
                            <td>Tunniste</td>
                            <td>{{ patient.id }}</td>
                        </tr>
                        <tr>
                            <td>Lisääjä</td>
                            <td>
                                <template v-if="patient.adder">
                                    {{ patient.adder.name }}
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td>Lisätty</td>
                            <td>{{ patient.created_at }}</td>
                        </tr>
                        <tr>
                            <td>Hoitomuoto</td>
                            <td>
                                {{ getTreatmentTypeByGroup(patient.group) }}
                            </td>
                        </tr>
                        <tr>
                            <td>Potilaan ryhmä</td>
                            <td>
                                <template v-if="patient.group === null">
                                    Odottaa satunnaistamista
                                </template>
                                <template v-else>
                                    {{ patient.group + 1 }}
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            <v-col cols="12" sm="6">
                <v-alert v-if="patient.group === null" type="error" prominent>
                    <p><strong>Potilasta ei ole satunnaistettu</strong></p>
                    <p>
                        Potilasta ei ole vielä satunnaistettu, koska
                        satunnaistaminen suoritetaan lohkoissa. Potilas
                        satunnaistetaan heti kun tarvittava määrä muita
                        potilaita on kirjattu järjestelmään.
                    </p>
                </v-alert>
                <template v-if="patient.group === 0">
                    <v-alert type="error" icon="mdi-heart-pulse">
                        Akuutti rytminsiirto
                    </v-alert>
                    <p>
                        Potilaan hoito toteutuu normaalin hoitolinjan mukaan,
                        siten, että potilaalle tehdään päivystyksellinen
                        rytminsiirto 48 tunnin sisään rytminhäiriön alusta.
                    </p>
                    <p>
                        Rytminsiirron jälkeen potilaan voi kotiuttaa
                        normaalisti. Potilas kutsutaan noin viikon päästä
                        kardiologian poliklinikalle
                    </p>
                </template>
                <template v-if="patient.group === 1">
                    <v-alert type="warning" icon="mdi-test-tube">
                        Viivästetty rytminsiirto
                    </v-alert>
                    <p>
                        Potilaan voi kotiuttaa välittömästi. Varmista, että
                        potilaalle on määrätty antikoagulaatiolääkitys
                    </p>
                    <p>
                        Potilas kutsutaan noin viikon päästä kardiologian
                        poliklinikalle, jolloin tehdään rytminsiirto jos potilas
                        on edelleen eteisvärinä-tai eteislepatusrytmissä
                    </p>
                </template>

                <template v-if="patient.group !== null">
                    <p>Potilaan tunnistenumero on: {{ patient.id }}</p>
                    <p>
                        Säilytä potilaan tutkimuslomakkeet tutkimuskansioon
                    </p>
                </template>

                <v-card v-if="patient.group !== null">
                    <v-card-title>
                        Esimerkkikirjaus sairaskertomustekstiin
                    </v-card-title>
                    <v-card-subtitle>
                        (Tämän voi kopioida suoraan)
                    </v-card-subtitle>

                    <v-card-text
                        class="text-justify"
                        lang="fi"
                        style="hyphens: auto"
                    >
                        {{ exampleDescription }}
                    </v-card-text>

                    <v-card-actions v-if="canCopyClipboard">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="copyExample">
                            Kopioi
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                exampleDescription1:
                    "Potilas on rekrytoitu AFFELECT-tutkimukseen, jossa vertaillaan satunnaistetussa asetelmassa akuutin eteisvärinä- tai eteislepatuskohtauksen kahta eri hoitomuotoa. Potilas on satunnaistettu akuuttiin rytminsiirto –hoitolinjaan, jossa potilaalle tehdään päivystyksellinen rytminsiirto. Potilas kutsutaan noin viikon kuluessa kardiologian poliklinikalle jatkohoidon arvioon.",
                exampleDescription2:
                    "Potilas on rekrytoitu AFFELECT-tutkimukseen, jossa vertaillaan satunnaistetussa asetelmassa akuutin eteisvärinä- tai eteislepatuskohtauksen kahta eri hoitomuotoa. Potilas on satunnaistettu viivästettyyn rytminsiirto –hoitolinjaan, jossa rytminsiirto tehdään vasta tutkimuspoliklinikalla mikäli se on tarpeellinen tuolloin. Potilas kutsutaan noin viikon kuluessa kardiologian poliklinikalle, jossa tehdään myös jatkohoidon arvio.",
                patient: {}
            };
        },

        methods: {
            fetchPatient() {
                const id = this.$route.params.id;
                this.$http
                    .get(`/api/patients/${id}`)
                    .then(response => (this.patient = response.data));
            },

            getTreatmentTypeByGroup(group) {
                return group === 0 ? "Normaali hoito" : "Kokeellinen hoito";
            },

            copyExample() {
                navigator.clipboard.writeText(this.exampleDescription).then(
                    () => {
                        this.$dialog.notify.success(
                            "Sairaskertomus kopioitiin leikepöydälle",
                            {
                                timeout: 1000
                            }
                        );
                    },
                    err => {
                        this.$dialog.notify.error("Leikepöydälle ei voi kopioida");
                    }
                );
            }
        },

        computed: {
            canCopyClipboard() {
                return "clipboard" in navigator;
            },

            exampleDescription() {
                return this.patient.group === 0
                    ? this.exampleDescription1
                    : this.exampleDescription2;
            }
        },

        created() {
            this.fetchPatient();
        }
    };
</script>

<style scoped>
</style>